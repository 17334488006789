import Head from '../components/Head';
import React from 'react';
import WorkspaceHome from '../components/WorkspaceGrid/WorkspaceHome';
import { graphql, PageProps } from 'gatsby';
import { ContentfulWorkspaceCategoryWithLinkRequirements, Image, RichText } from 'types';

interface GetInTouchPageData {
  workspacesGrid: {
    heading: string;
    description: RichText;
    sections: Array<ContentfulWorkspaceCategoryWithLinkRequirements>;
    thumbnail: { image: Image };
  };
}

export default function Workspaces({ data: { workspacesGrid }, location }: PageProps<GetInTouchPageData>) {
  const heading = workspacesGrid.heading;
  const description = workspacesGrid.description;
  const image = workspacesGrid.thumbnail?.image?.file.url;
  const sections = workspacesGrid.sections;
  return (
    <>
      <Head location={location} title={heading} description={description} image={image} />
      <WorkspaceHome heading={heading} description={description} sections={sections} />
    </>
  );
}

export const query = graphql`
  query {
    workspacesGrid: contentfulPage(id: { eq: "256962f6-4cfd-5655-9316-dc7cb36786d6" }) {
      heading
      description {
        raw
      }
      sections {
        ...WorkspaceCategory
      }
      thumbnail {
        image {
          ...Image
        }
      }
    }
  }
`;
