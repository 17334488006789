import NavGrid, { GridItem, NavGridItemStill } from '../../generic/NavGrid';
import { Link, graphql } from 'gatsby';
import Section from '../../generic/Section';
import styles from './index.module.scss';
import getRoute from '../../../routes/getRoute';
import NavGridItemHero from '../../generic/NavGrid/NavGridItemHero';
import React from 'react';
import styled from 'styled-components';
import { branding, universalMargin, mediaQueries } from 'styles';
import InitialMargin from 'components/generic/InitialMargin';
import { getBuildTarget } from '../../../white-label';

/**
 * @param {object} props
 * @param {Array<import('types').ContentfulWorkspaceCategoryWithLinkRequirements>} props.sections
 * @param {string} props.heading
 * @param {string|import('types').RichText} props.description
 */
export default function WorkspaceHome({ heading, description, sections }) {
  return (
    <StyledSection size="max" className={styles[getBuildTarget()]}>
      <InitialMargin />
      <NavGrid mapRowHeightToColumnWidth>
        <NavGridItemHero heading={heading} description={description} />
        {sections.map(category => {
          let target = '';
          if (category.workspaceSubCategories?.length === 1) {
            let [subCategory] = category.workspaceSubCategories;
            let [firstWorkspace] = subCategory.workspaces;
            target = getRoute(firstWorkspace);
          } else {
            target = getRoute(category);
          }

          let [gridImage] = category.images;
          let { x, y } = {
            x: !isNaN(gridImage?.focalPoint?.x) ? gridImage.focalPoint.x : 50,
            y: !isNaN(gridImage?.focalPoint?.y) ? gridImage?.focalPoint?.y : 50,
          };
          let imagePosition = `${x}% ${y}%`;

          return (
            <GridItem key={category.id} gridSize={category.gridSize}>
              <Link className={styles.link} to={target}>
                <NavGridItemStill
                  key={category.id}
                  objectFit="cover"
                  objectPosition={imagePosition}
                  heading={category.heading}
                  description={category.description?.description}
                  gridSize={category.gridSize}
                  image={gridImage.image}
                  numberOfSeats={category.numberOfSeats}
                  gridImagePad
                />
              </Link>
            </GridItem>
          );
        })}
      </NavGrid>
    </StyledSection>
  );
}

const StyledSection = styled(Section)`
  background-color: ${branding.cardGrid.background};
  margin-bottom: ${universalMargin * 8}px;
  ${mediaQueries.large(`margin-bottom: ${universalMargin * 16}px;`)}
`;

export const fragment = graphql`
  fragment WorkspaceCategory on ContentfulWorkspaceCategory {
    __typename
    id
    heading
    slug
    numberOfSeats
    body {
      body
    }
    description {
      description
    }
    images {
      ...PwpImageBase
      image {
        ...ImageBase
        gatsbyImageData(layout: CONSTRAINED, width: 640, placeholder: NONE)
      }
    }
    gridSize
    workspaceSubCategories {
      slug
      workspaces {
        slug
      }
    }
  }
`;
